import { reservasDetallesClear, setReservasDetalles } from '../reservasDetalles/actions';
import { all, call, fork, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import {
    loadingRO,
    reservasOperacionesClear,
    reservasOperacionesSetData,
    reservasOperacionesSetSucesores,
    reservasOperacionesTotalesEstadosUpdate
} from './actions';
import { Action, ProveedorBooking } from '../../../interfaces/general';
import {

    RESERVAS_OPERACIONES_GET_TOTALES,
    RESERVAS_OPERACIONES_PROCESS_UNLOCK,
    RESERVAS_OPERACIONES_ALL,
    RESERVAS_OPERACIONES_GET_BY_ESTADO,
    RESERVAS_OPERACIONES_RESERVAS_ID_ALL,
    RESERVAS_OPERACIONES_ENVIAR_LISTA_PASAJEROS,
    RESERVAS_OPERACIONES_ABRIR_LISTA_PASAJEROS,
    RESER_OPS_UPDATE_TRESERVAS_BLOQUEADAS
} from './types';
import {
    getDetallesAll,
    getSucesoresValidos,
    postEliminarReservas,
    totalEstadosReserva,
    getReservaDetalleBy, postEnviarListaPasajeros, getReservasTipoEstados
} from "../../../helpers/reservasServices";
import { notifications } from "../../../helpers/notifications";
import { RootState } from '../../index';
import {
    RESERVAS_ACTIONS_IS_ALL_ACTIVE,
    RESERVAS_ACTIONS_OPEN_UNLOCK,
    RESERVAS_ACTIONS_PROCESSING_LETTER
} from '../reservasActions/type';
import { Reserva } from '../../../interfaces/reserva';
import { ReservaDetallesChangeSend } from '../../../interfaces/sends';
import { ReservaDetalle } from '../../../interfaces/reservasDetalle';
import { RESERVAS_DETALLES_CHANGE_ESTADO_SET, RESERVAS_DETALLES_LOADING } from '../reservasDetalles/types';
import { dateTimeToDateForms } from "../../../helpers/utilsDate";

const getFiltroReservado = (estadoId: number, estadoReservado: number, fechaInicio: Date, fechaFin: Date) => {
    let filtro = '';
    if (estadoId === estadoReservado) {
        filtro = `and ReservaDetalle/any(c: c/FechaInicio ge ${dateTimeToDateForms(fechaInicio)} and c/FechaFin le ${dateTimeToDateForms(fechaFin)} and c/EsReservaVigente eq true)`;
    }
    return filtro;
}

/**
 *
 * @param payload number[]  estados de la reserva ejm: 21 Reserva Pendiente
 */
function* getReservaByEstado({ payload: currentEstados }: Action<number[]>): any {
    try {
        const { ReservasOperaciones, ReservasActions, Catalogos } = yield select((state: RootState) => state);
        const { filtroReservado } = ReservasOperaciones;
        const { estados } = Catalogos;
        let filtro = getFiltroReservado(currentEstados[0], estados['Reserva']['Reservado'].EstadoId, filtroReservado.fechaInicio, filtroReservado.fechaFin);
        const { tipo } = ReservasActions;// Tipo => Hotel, alimentos
        if (tipo !== "" && currentEstados[0] !== 0) {
            yield put(loadingRO(true));
            yield put(reservasOperacionesClear());
            yield put(reservasDetallesClear());
            yield put({ type: RESERVAS_ACTIONS_IS_ALL_ACTIVE, payload: false });
            const reservaData = yield call(getReservasTipoEstados, tipo, currentEstados, filtro, estados['Reserva']);
            if (reservaData.length !== 0) {
                if (currentEstados[0] !== estados['Reserva']['Reservado'].EstadoId
                    && currentEstados[0] !== estados['Reserva']['Cancelado'].EstadoId
                    && currentEstados[0] !== estados['Reserva']['Negado'].EstadoId) {
                    yield call(setTotalesReservas, reservaData, currentEstados);
                }
                yield put(reservasOperacionesSetData(reservaData));
                const sucesores = yield call(getSucesoresValidos);
                yield put(reservasOperacionesSetSucesores(sucesores));
            }else {
                yield call(setTotalesReservas, [], currentEstados); 
            }
            yield put(loadingRO(false));
        }
    } catch (error) {
        yield put(loadingRO(false));
        notifications({ ex: error });
    }
}

function* processUnlock(): any {
    const { ReservasOperaciones, ReservasActions } = yield select((state: RootState) => state);
    const { selected, reservasBloqueadas } = ReservasOperaciones;
    const { currentPathEstado } = ReservasActions;
    try {
        yield call(postEliminarReservas, selected.ReservaId);
        yield put({ type: RESERVAS_OPERACIONES_GET_BY_ESTADO, payload: currentPathEstado.estadosId });
        yield put(reservasDetallesClear());
        yield put({ type: RESER_OPS_UPDATE_TRESERVAS_BLOQUEADAS, payload: reservasBloqueadas + 1 })
        notifications({ title: 'Exito', message: ``, toastType: 'success' });
    } catch (error) {
        notifications({ ex: error });
    } finally {
        yield put({ type: RESERVAS_ACTIONS_OPEN_UNLOCK, payload: false });
    }
}

function* enviarCorreoListaPasajeros(): any {
    yield put({ type: RESERVAS_ACTIONS_PROCESSING_LETTER, payload: true });
    const { selected } = yield select((state: RootState) => state.ReservasOperaciones);

    try {
        yield call(postEnviarListaPasajeros, selected.BookingId, selected.ProveedorId);
        notifications({ title: 'Exito', message: `Lista de pasajeros enviada con éxito`, toastType: 'success' });
    } catch (error) {
        notifications({ ex: error });
    } finally {
        yield put({ type: RESERVAS_ACTIONS_PROCESSING_LETTER, payload: false });
        yield put({ type: RESERVAS_OPERACIONES_ABRIR_LISTA_PASAJEROS, payload: false });
    }
}

/**
 * Coloca los totales de los estados dependiendo del estado de la reserva
 * @param reservas
 */
export function* setTotalesReservas(reservas: Reserva[], estadosActuales: number[]) {
    const { estados } = yield select((state: RootState) => state.Catalogos);
    const result = totalEstadosReserva(reservas, estados['Reserva'], estadosActuales);
    yield put(reservasOperacionesTotalesEstadosUpdate(result?.rp, result?.cp));
}

function* getReservasTotales({ payload }: Action<string>): any {
    try {
        const { estados } = yield select((state: RootState) => state.Catalogos);
        const reservas = yield call(getReservasTipoEstados, payload, [
            estados['Reserva']['Reserva Pendiente'].EstadoId,
            estados['Reserva']['Modificación Pendiente'].EstadoId,
            estados['Reserva']['Cancelación Pendiente'].EstadoId], '', estados['Reserva']);
        yield call(setTotalesReservas, reservas, []);
    } catch (error) {
        notifications({ ex: error })
    }
}

function* getAllReservas({ payload }: Action<ProveedorBooking>): any {
    try {
        const { Catalogos: { estados }, ReservasActions: { currentPathEstado } } = yield select((state: RootState) => state);
        const { name: estadoPath } = currentPathEstado;
        const { bookings } = payload;
        yield put({ type: RESERVAS_DETALLES_LOADING, payload: true });
        let promises: any[] = [];
        let reservaCartaEnviada: { reservaId: number, cartaEnviada: boolean }[] = [];
        let reservasId: number[] = [];
        bookings.forEach((b: any) => {
            promises.push(getReservaDetalleBy(b.ReservaId, b.EstadoId, estados['Reserva'], estadoPath));
            reservaCartaEnviada.push({ reservaId: b.ReservaId, cartaEnviada: b.CartaEnviada });
            reservasId.push(b.ReservaId);
        });
        const detallesAll = yield call(getDetallesAll, promises);
        yield put(setReservasDetalles(detallesAll));
        const changeDetalle: ReservaDetallesChangeSend[] = [];
        detallesAll.forEach((rd: ReservaDetalle) => {
            changeDetalle.push({
                reservaDetalleId: rd.ReservaDetalleId,
                estadoActualizadoId: rd.EstadoId,
                reservaId: rd.ReservaId,
                cartaEnviada: reservaCartaEnviada.find(r => r.reservaId === rd.ReservaId)?.cartaEnviada
            });
        });
        yield put({ type: RESERVAS_DETALLES_CHANGE_ESTADO_SET, payload: changeDetalle });
        yield put({ type: RESERVAS_OPERACIONES_RESERVAS_ID_ALL, payload: reservasId });
        yield put({ type: RESERVAS_DETALLES_LOADING, payload: false });
        yield put({ type: RESERVAS_ACTIONS_IS_ALL_ACTIVE, payload: true });
    } catch (error) {
        yield put(setReservasDetalles([]));
        notifications({ ex: error });
    }
}

/**
 * Watchers
 */

function* watchAllReservas() {
    yield takeEvery(RESERVAS_OPERACIONES_ALL, getAllReservas);
}

function* watchTotales() {
    yield takeLatest(RESERVAS_OPERACIONES_GET_TOTALES, getReservasTotales);
}

function* watchReservaByEstado() {
    yield takeEvery(RESERVAS_OPERACIONES_GET_BY_ESTADO, getReservaByEstado);
}

function* watchProcessUnLock() {
    yield takeEvery(RESERVAS_OPERACIONES_PROCESS_UNLOCK, processUnlock);
}

function* watchEnviarListaPasajeros() {
    yield takeEvery(RESERVAS_OPERACIONES_ENVIAR_LISTA_PASAJEROS, enviarCorreoListaPasajeros);
}


function* reservasOperacionesSagas() {
    yield all([
        fork(watchReservaByEstado),
        fork(watchProcessUnLock),
        fork(watchTotales),
        fork(watchAllReservas),
        fork(watchEnviarListaPasajeros),
    ]);
}

export default reservasOperacionesSagas;